/**Utilities */
import { useState } from "react";
import { ErrorValidate, ErrorBackend } from "../../utilities";
/**Select */
import Select from "react-select";

interface InformacionAcademiaProps {
  formik: any;
  handleInputChange: any;
  errors: any;
  activeTab: number;
  handleSelectChange: any;
  ubigeoSearch: any;
  handleFormData: (label: string, value: string) => void;
}

interface OptionSelect {
  label: string;
  value: string;
}

export const InformacionAcademia = (props: InformacionAcademiaProps) => {
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [optionsCity, setOptionsCity] = useState<OptionSelect[]>([]);

  const handleSearchCity = (value: string) => {
    if (value.length >= 3) {
      props
        .ubigeoSearch(value)
        .then((response: any) => {
          setIsLoadingCity(true);
          setOptionsCity(response.data);
        })
        .finally(() => {
          setIsLoadingCity(false);
        });
    }
  };

  const handleCitySelectChange = (selectedOption: OptionSelect, key: string) => {
    setOptionsCity([]);
    props.handleFormData(key, selectedOption.value);
  };

  return (
    <div className={`tab-pane${props.activeTab === 3 ? " active" : ""}`}>
      <div className="row">
        {/* ¿Cómo te enteraste del evento? */}
        <div className="form-group col-md-6">
          <label htmlFor="marketing" className="form-label">
            ¿Cómo te enteraste del evento?
          </label>
          <select
            onChange={props.handleSelectChange}
            value={props.formik.values.marketing ?? ""}
            name="marketing"
            className={"form-control form-control-sm form-select" + (props.formik.errors.marketing && props.formik.touched.marketing ? " is-invalid" : "")}
            id="marketing"
          >
            <option value="">Seleccionar</option>
            <option value="FACEBOOK">Facebook</option>
            <option value="AMIGOS">Amigos</option>
            <option value="INSTAGRAM">Instagram</option>
            <option value="TIKTOK">TikTok</option>
            <option value="WEB">Página web</option>
          </select>
          <ErrorValidate state={props.formik.errors.marketing} />
        </div>
        {/* Código de embajador */}
        <div className="form-group col-md-6">
          <label htmlFor="code_ambassador" className="form-label">
            Ingresa el código de tu embajador y/o CIP
          </label>
          <input
            name="code_ambassador"
            onChange={props.handleInputChange}
            value={props.formik.values.code_ambassador}
            autoComplete="off"
            type="text"
            className={"todo-mayuscula form-control form-control-sm"}
            id="code_ambassador"
          />
          <ErrorBackend errorsBackend={props.errors} name="code_ambassador" />
        </div>
        {/* Centro de estudios */}
        <div className="form-group col-md-12">
          <label htmlFor="study_center" className="form-label">
            Centro de estudios (Completo)
          </label>
          <input
            name="study_center"
            onChange={props.handleInputChange}
            value={props.formik.values.study_center}
            autoComplete="off"
            type="text"
            className={
              "todo-mayuscula form-control form-control-sm" + (props.formik.errors.study_center && props.formik.touched.study_center ? " is-invalid" : "")
            }
            id="study_center"
          />
          <ErrorValidate state={props.formik.errors.study_center} />
          <ErrorBackend errorsBackend={props.errors} name="study_center" />
        </div>
        {/* Nombre de la carrera/profesión */}
        <div className="form-group col-md-12">
          <label htmlFor="career" className="form-label">
            Nombre de la carrera/profesión
          </label>
          <input
            name="career"
            onChange={props.handleInputChange}
            value={props.formik.values.career}
            autoComplete="off"
            type="text"
            className={"todo-mayuscula form-control form-control-sm" + (props.formik.errors.career && props.formik.touched.career ? " is-invalid" : "")}
            id="career"
          />
          <ErrorValidate state={props.formik.errors.career} />
          <ErrorBackend errorsBackend={props.errors} name="career" />
        </div>
        {/* Ciudad */}
        <div className="form-group col-md-12">
          <label htmlFor="city" className="form-label">
            Ciudad
          </label>
          <Select
            name="city"
            id="city"
            isLoading={isLoadingCity}
            placeholder={"Buscar ciudad..."}
            options={optionsCity}
            onInputChange={handleSearchCity}
            noOptionsMessage={() => "No se encontraron resultados"}
            value={props.formik.values.selectedCity}
            onChange={(selectedOption) => handleCitySelectChange(selectedOption, "city")}
            className={props.formik.errors.city && props.formik.touched.city ? " is-invalid" : ""}
          />
          <ErrorValidate state={props.formik.errors.city} />
          <ErrorBackend errorsBackend={props.errors} name="city" />
        </div>
      </div>
    </div>
  );
};

export default InformacionAcademia;
