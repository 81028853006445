//import * as niubizService from "../../services/niubiz.service";
import moment from "moment";
import "moment/locale/es";
import { ChangeEvent } from "react";
import { TransactionResponse } from "../../models/responses";
import { SweetAlert } from "../../utilities";
import { useNavigate } from "react-router-dom";
interface PagoProps {
  formik: any;
  handleInputChange: any;
  errors: any;
  activeTab: number;
  handleSelectChange: any;
  requirements: any;
  transactions: any;
  handleFormData: (label: string, value: string) => void;
  aplicarDescuento: any;
  validateCoupon: any;
  reiniciarDescuento: any;
  statePayment: boolean;
  setStatePayment: any;
  terminos: boolean;
  setTerminos: any;
  pagar: boolean;
  setPagar: any;
}

declare global {
  interface Window {
    VisanetCheckout: any;
  }
}

export const Pago = (props: PagoProps) => {
  const evento = props.formik.values.event;
  const startDateMoment = moment(evento?.stage?.start_date);
  const endDateMoment = moment(evento?.stage?.end_date);
  const navigate = useNavigate();

  const handleStageType = (event: ChangeEvent<HTMLSelectElement>) => {
    props.setStatePayment(false);
    props.setPagar(false);
    props.reiniciarDescuento();

    props.handleSelectChange(event);
    // eslint-disable-next-line
    const amount = evento?.stage?.stage_types.find((stage: { id: any }) => stage.id == event.target.value).price;
    props.handleFormData("amount", amount);
    props.setStatePayment(true);
  };

  const generatePayment = async () => {
    props.transactions(props.formik.values).then((response: TransactionResponse) => {
      if (response.state) {
        if (response.state_transaction === 2) {
          navigate("/transaction/" + response.uuid);
        } else if (response.state_transaction === 0) {
          renderBotonPago(response.sesionKeyNiubiz, response.uuid, response.purchase_number);
          props.setPagar(true);
        }
      } else {
        SweetAlert.error("Error", response.message);
        props.reiniciarDescuento();
      }
    });
  };

  const renderBotonPago = (sesionToken: string, uuid: string, purchasenumber: string) => {
    window.VisanetCheckout.configure({
      sessiontoken: sesionToken,
      channel: "web",
      merchantid: "651000641",
      purchasenumber: purchasenumber,
      amount: (props.formik.values.amount - props.formik.values.mtoDescuento).toFixed(2),
      expirationminutes: "20",
      timeouturl: "https://inscripciones.apeii.org",
      merchantlogo: "https://inscripcion-backend.coneii.org/images/logo.png",
      formbuttoncolor: "#7367FF",
      action: `https://inscripcion-backend.coneii.org/api/inscription/v1/generate_payment_authorization?uuid=${uuid}`,
    });
    window.VisanetCheckout.open();
  };

  const handleTerminosChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setTerminos(event.target.checked);
  };

  return (
    <div className={`tab-pane${props.activeTab === 4 ? " active" : ""}`}>
      <h4 className="text-center mb-0">
        <b>{evento?.stage?.name}</b>
      </h4>
      <h6 className="text-center">{`Del ${startDateMoment.format("DD MMM")} al ${endDateMoment.endOf("month").format("DD MMM")}`}</h6>
      <div className="row mt-3">
        <div className="form-group col-md-6">
          <label htmlFor="stage_type_id" className="form-label">
            Tipo de entrada
          </label>
          <select
            onChange={handleStageType}
            value={props.formik.values.stage_type_id ?? ""}
            name="stage_type_id"
            className={"form-control form-control-sm form-select"}
            id="stage_type_id"
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {evento?.stage?.stage_types?.map((stage_type: any) => (
              <option key={stage_type.id} value={stage_type.id}>
                {stage_type.name} - S/{stage_type.price}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group  col-md-6">
          <label htmlFor="name">Cupon de descuento</label>
          <div role="group" className="btn-group">
            <input
              disabled={props.formik.values.stage_type_id === ""}
              autoComplete="off"
              type="text"
              name="discount"
              value={props.formik.values.discount}
              onChange={props.handleInputChange}
              className={`todo-mayuscula form-control form-control-sm ${
                props.validateCoupon.state === true ? "is-valid" : props.validateCoupon.state === false ? "is-invalid" : ""
              }`}
            />
            <button disabled={props.formik.values.stage_type_id === ""} type="button" onClick={props.aplicarDescuento} className="btn btn-sm btn-primary">
              Aplicar
            </button>
          </div>
          {props.validateCoupon.state ? (
            <div className="form-group__success">
              <p>{props.validateCoupon.message}</p>
            </div>
          ) : (
            <div className="form-group__error">
              <p>{props.validateCoupon.message}</p>
            </div>
          )}
        </div>
        <div className="form-group col-md-12">
          <div className="form-check">
            <input id="terminos" type="checkbox" className="form-check-input form-check-input" checked={props.terminos} onChange={handleTerminosChange} />
            <label htmlFor="terminos" className="form-check-label form-label">
              Acepto los términos, condiciones y tratamiento de datos personales.
            </label>
          </div>
        </div>
        {props.statePayment && (
          <>
            <div className="col-md-12 text-center mt-4">
              <button disabled={!props.terminos || props.pagar} onClick={generatePayment} type="button" className="btn btn-lg btn-primary">
                Pagar S/ {(props.formik.values.amount - props.formik.values.mtoDescuento).toFixed(2)}
              </button>
            </div>
            <div className="col-md-12 text-center">
              <img src="/images/pago.png" alt="Pago online" className="logo-pago" />
            </div>
          </>
        )}

        <div className="col-md-12 mt-4">
          <p className="text-justify">
            De acuerdo a lo dispuesto por la ley 29733 (Ley de protección de Datos Personales) y su reglamento, quien suscribe brinda su consentimiento de
            manera libre, previa, inequívoca, informada y expresa a CONEII para que realice el almacenamiento y tratamiento de mis datos personales para enviar
            toda la información relacionada a otros eventos o actividades. Así como también acepto mi participación en los videos/imagenes capturadas durante el
            evento y su uso para publicaciones en redes sociales u otros medios pertenecientes únicamente a CONEII y APEII.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pago;
