/**Utilities */
import { ErrorValidate, ErrorBackend } from "../../utilities";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";
/**Models */
import { Person } from "../../models";

interface DatosPersonalesProps {
  formik: any;
  handleInputChange: any;
  handleSelectChange: any;
  errors: any;
  activeTab: number;
  onPersonSearch: any;
  handlePerson: (person: Person) => void;
  handleFormData: (label:string, value:string) => void;
}

export const DatosPersonales = (props: DatosPersonalesProps) => {
  const handleDateChange = (date: Date[]) => {
    const birthdate = moment(date[0]).format("YYYY-MM-DD");
    props.formik.setFieldValue("birthdate", birthdate);
    props.handleFormData("birthdate", birthdate);
  };

  const onKeyUpSearchPerson = () => {
    if (props.formik.values.document_number.length < 8) return;
    props.onPersonSearch(props.formik.values.document_number).then((response: Person) => {
      if (response) {
        props.handlePerson(response);
      }
    });
  };

  return (
    <div className={`tab-pane${props.activeTab === 2 ? " active" : ""}`}>
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="document_number" className="form-label">
            DNI
          </label>
          <input
            name="document_number"
            onChange={props.handleInputChange}
            onKeyUp={onKeyUpSearchPerson}
            value={props.formik.values.document_number}
            autoComplete="off"
            type="text"
            className={
              "todo-mayuscula form-control form-control-sm" + (props.formik.errors.document_number && props.formik.touched.document_number ? " is-invalid" : "")
            }
            id="document_number"
          />
          <ErrorValidate state={props.formik.errors.document_number} />
          <ErrorBackend errorsBackend={props.errors} name="beca" />
          <ErrorBackend errorsBackend={props.errors} name="document_number" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="names" className="form-label">
            Nombres
          </label>
          <input
            name="names"
            onChange={props.handleInputChange}
            value={props.formik.values.names}
            autoComplete="off"
            type="text"
            className={"todo-mayuscula form-control form-control-sm" + (props.formik.errors.names && props.formik.touched.names ? " is-invalid" : "")}
            id="names"
          />
          <ErrorValidate state={props.formik.errors.names} />
          <ErrorBackend errorsBackend={props.errors} name="beca.names" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="father_last_name" className="form-label">
            Apellido paterno
          </label>
          <input
            name="father_last_name"
            onChange={props.handleInputChange}
            value={props.formik.values.father_last_name}
            autoComplete="off"
            type="text"
            className={
              "todo-mayuscula form-control form-control-sm" +
              (props.formik.errors.father_last_name && props.formik.touched.father_last_name ? " is-invalid" : "")
            }
            id="father_last_name"
          />
          <ErrorValidate state={props.formik.errors.father_last_name} />
          <ErrorBackend errorsBackend={props.errors} name="beca.father_last_name" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="mother_last_name" className="form-label">
            Apellido materno
          </label>
          <input
            name="mother_last_name"
            onChange={props.handleInputChange}
            value={props.formik.values.mother_last_name}
            autoComplete="off"
            type="text"
            className={
              "todo-mayuscula form-control form-control-sm" +
              (props.formik.errors.mother_last_name && props.formik.touched.mother_last_name ? " is-invalid" : "")
            }
            id="mother_last_name"
          />
          <ErrorValidate state={props.formik.errors.mother_last_name} />
          <ErrorBackend errorsBackend={props.errors} name="beca.mother_last_name" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="birthdate" className="form-label">
            Fecha de nacimiento
          </label>
          <Flatpickr
            id="birthdate"
            value={props.formik.values.birthdate}
            onChange={handleDateChange}
            className={"form-control form-control-sm" + (props.formik.errors.birthdate && props.formik.touched.birthdate ? " is-invalid" : "")}
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
            }}
          />
          <ErrorValidate state={props.formik.errors.birthdate} />
          <ErrorBackend errorsBackend={props.errors} name="beca.birthdate" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="gender" className="form-label">
            Género
          </label>
          <select
            onChange={props.handleSelectChange}
            value={props.formik.values.gender ?? ""}
            name="gender"
            className={"form-control form-control-sm form-select" + (props.formik.errors.gender && props.formik.touched.gender ? " is-invalid" : "")}
            id="gender"
          >
            <option value="" disabled>
              Seleccionar
            </option>
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
            <option value="N">No especificar</option>
          </select>
          <ErrorValidate state={props.formik.errors.gender} />
          <ErrorBackend errorsBackend={props.errors} name="beca.gender" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email" className="form-label">
            Correo
          </label>
          <input
            name="email"
            onChange={props.handleInputChange}
            value={props.formik.values.email}
            autoComplete="off"
            type="text"
            className={"form-control form-control-sm" + (props.formik.errors.email && props.formik.touched.email ? " is-invalid" : "")}
            id="email"
          />
          <ErrorValidate state={props.formik.errors.email} />
          <ErrorBackend errorsBackend={props.errors} name="beca.email" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="cellphone" className="form-label">
            Celular
          </label>
          <input
            name="cellphone"
            onChange={props.handleInputChange}
            value={props.formik.values.cellphone}
            autoComplete="off"
            type="text"
            className="form-control form-control-sm"
            id="cellphone"
          />
          <ErrorBackend errorsBackend={props.errors} name="beca.cellphone" />
        </div>
      </div>
    </div>
  );
};

export default DatosPersonales;
