import { useEffect, useState } from "react";
import Tabs from "../Register/tabs";
import { useNavigate, useParams } from "react-router-dom";
import { useInscriptions } from "../../hooks";
import { AuthorizationResponse } from "../../models/responses";

export const Transaction = () => {
  const [activeTab] = useState(4);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { authorizationPayment } = useInscriptions();
  const [response, setResponse] = useState<AuthorizationResponse>();

  useEffect(() => {
    const dataInicial = () => {
      authorizationPayment(uuid ?? "").then((res) => {
        if (res.state) {
          setResponse(res);
          setLoadedComponent(true);
        } else {
          navigate("/");
        }
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [authorizationPayment, loadedComponent, navigate, uuid]);

  return loadedComponent ? (
    <div className="min-vh-100 row justify-content-center align-items-center">
      <div className="col-md-7">
        <div className="card card-featured card-featured-primary mb-3 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 mb-3 text-center">
                <img src="/images/logo.png" alt="" className="logo-inscription" />
                <h4 className="mt-3">Inscripción online</h4>
              </div>
              <div className="col-md-12 twitter-bs-wizard">
                <Tabs activeTab={activeTab} />
                <div className="tab-content twitter-bs-wizard-tab-content">
                  <div className={`tab-pane${activeTab === 4 ? " active" : ""}`}>
                    <div className="row justify-content-center">
                      <div className="col-lg-6">
                        <div className="text-center">
                          <div className="mb-4">
                            {response?.state_payment ? (
                              <i className="fa-regular fa-circle-check text-success display-4"></i>
                            ) : (
                              <i className="fa-regular fa-circle-xmark text-danger display-4"></i>
                            )}
                          </div>
                          <div>
                            <h5>{response?.message}</h5>
                            <p className="text-muted">{response?.message_payment}.</p>
                            {response?.state_payment ? (
                              <>
                                <p className="m-0">
                                  <b>Número de pedido: </b>
                                  {response?.purchase_number}
                                </p>
                                <p className="m-0">
                                  <b>Nombre y apellidos: </b>
                                  {response?.names}
                                </p>
                                <p className="m-0">
                                  <b>Fecha y hora del pedido: </b>
                                  {response?.date_time}
                                </p>
                                <p className="m-0">
                                  <b>Importe de la transacción: </b>
                                  S/{response?.amount}
                                </p>
                                <p className="m-0">
                                  <b>Tipo de moneda: </b>
                                  {response?.coin}
                                </p>
                                <p className="m-0">
                                  <b>Tarjeta: </b>
                                  {response?.card_number}
                                </p>
                                <p className="m-0">
                                  <b>Marca: </b>
                                  {response?.brand}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="m-0">
                                  <b>Número de pedido: </b>
                                  {response?.purchase_number}
                                </p>
                                <p className="m-0">
                                  <b>Fecha y hora del pedido: </b>
                                  {response?.date_time}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Transaction;
