interface DatosPersonalesProps {
  formik: any;
  activeTab: number;
  requirements: any;
  setActiveTab: (tab: number) => void;
}


export const DatosPersonales = (props: DatosPersonalesProps) => {
  const onEvent = (event: any) => {
    props.formik.setFieldValue("event", event);
    props.setActiveTab(2);
  };
  return (
    <div className={`tab-pane${props.activeTab === 1 ? " active" : ""}`}>
      <div className="row">
        {props.requirements.map((event: any, index: number) => (
          <div key={index} className="col-md-4 mt-3">
            <div className="card card-event">
              <div className="card-header">
                <img className="imgCongress" src={`${process.env.REACT_APP_URL_BACKEND}/images/congresses/${event.id}.png`} alt={event.name} />
              </div>
              <div className="card-footer text-center">
                <button type="button" onClick={() => onEvent(event)} className="btn btn-primary btn-sm">
                  Entradas
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatosPersonales;
