import api from "./api";
/**Models */
import { Person } from "../models";
import { InscripcionRequest } from "../models/requests";
import { AuthorizationResponse, DiscountResponse, TransactionResponse } from "../models/responses";

export const personSearch = async (document: string) => {
  try {
    const response = await api.get<Person>(`/persons/find?document_number=${document}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const ubigeoSearch = async (text: string) => {
  try {
    const response = await api.get(`/ubigeos?text=${text}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const requirements = async () => {
  try {
    const response = await api.get(`/requirements-api`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const transactions = async (request: InscripcionRequest) => {
  try {
    const response = await api.post<TransactionResponse>(`/transactions`, { transaction: request });
    return response;
  } catch (error) {
    throw error;
  }
};

export const authorizationPayment = async (uuid: string) => {
  try {
    const response = await api.post<AuthorizationResponse>(`/authorization-payment/${uuid}`, {});
    return response;
  } catch (error) {
    throw error;
  }
};

export const applyDiscount = async (code: string, document_number: string) => {
  try {
    const response = await api.post<DiscountResponse>(`apply-discount`, {
      code,
      document_number,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const verificar = async (document: string) => {
  try {
    const response = await api.post(`/verificar`, {
      document: document,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
