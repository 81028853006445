import { configureStore } from '@reduxjs/toolkit';
import { loadingSlice } from './states/loading.slice';

export interface AppStore {
  loading: any;
}

export default configureStore<AppStore>({
  reducer: {
    loading: loadingSlice.reducer,
  },
});
