import * as Yup from "yup";

const validationSchema = [
  Yup.object().shape({}),
  Yup.object().shape({
    document_number: Yup.string().required("El campo 'DNI' es obligatorio."),
    names: Yup.string().required("El campo 'Nombres' es obligatorio."),
    father_last_name: Yup.string().required("El campo 'Apellido paterno' es obligatorio."),
    mother_last_name: Yup.string().required("El campo 'Apellido materno' es obligatorio."),
    birthdate: Yup.string().required("El campo 'Fecha de nacimiento' es obligatorio."),
    gender: Yup.string().required("El campo 'Género' es obligatorio."),
    email: Yup.string().required("El campo 'Correo electrónico' es obligatorio."),
  }),
  Yup.object().shape({
    marketing: Yup.string().required("El campo es obligatorio."),
    study_center: Yup.string().required("El campo 'Centro de estudios' es obligatorio."),
    career: Yup.string().required("El campo 'Carrera' es obligatorio."),
    city: Yup.string().required("El campo 'Ciudad' es obligatorio."),
  }),
];

export default validationSchema;
