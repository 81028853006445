/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
/**Components */
import { Loading, LoadingState } from '../components/shared';
/**Reduxs */
import { AppStore } from '../redux/store';

export const PageLayout = () => {
  const [loading, setLoading] = useState(true);
  const loadingState = useSelector((store: AppStore) => store.loading);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  });

  return loading ? (
    <Loading />
  ) : (
    <>
      <Tooltip id="tooltip-page" />
      <div id="layout-page" className="container">
        <Outlet />
        {loadingState.isLoading ? <LoadingState /> : null}
      </div>
    </>
  );
};

export default PageLayout;
