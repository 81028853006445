/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useEffect, useState } from "react";
/**Validations */
import { useFormik } from "formik";
import validationSchema from "./validationSchema";
/**Models */
import { InscripcionRequest } from "../../models/requests";
import { Person } from "../../models";
/**Hooks */
import { useInscriptions } from "../../hooks";
/**Components */
import DatosPersonales from "./DatosPersonales";
import Tabs from "./tabs";
import InformacionAcademia from "./InformacionAcademia";
import Pago from "./Pago";
import Eventos from "./Eventos";
//import { useNavigate } from "react-router-dom";

export const RegisterPage = () => {
  //const navigate = useNavigate();
  const defaultFormData: InscripcionRequest = {
    event: {},
    document_number: "",
    names: "",
    father_last_name: "",
    mother_last_name: "",
    birthdate: "",
    gender: "",
    email: "",
    cellphone: "",
    marketing: "",
    code_ambassador: "",
    study_center: "",
    career: "",
    city: "",
    stage_type_id: "",
    discount: "",
    mtoDescuento: 0,
    purchase_number: (Math.floor(Math.random() * 999999999999) + 1).toString(),
  };

  const { personSearch, ubigeoSearch, getRequirements, transactions, applyDiscount } = useInscriptions();
  const [requirements, setRequirements] = useState<any[]>([]);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [errors] = useState<string[]>([]);
  const currentValidationSchema = validationSchema[activeTab - 1];
  const [validateCoupon, setValidateCoupon] = useState<any>({});
  const [statePayment, setStatePayment] = useState<boolean>(false);
  const [terminos, setTerminos] = useState<boolean>(false);
  const [pagar, setPagar] = useState<boolean>(false);

  useEffect(() => {
    const dataInicial = () => {
      getRequirements()
        .then((response: any) => {
          setRequirements(response);
          setLoadedComponent(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [getRequirements, loadedComponent]);

  const formik = useFormik({
    initialValues: defaultFormData,
    validationSchema: currentValidationSchema,
    onSubmit: () => {
      if (activeTab !== 4) {
        setActiveTab(activeTab + 1);
      }
    },
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handlePerson = (person: Person) => {
    formik.setFieldValue("names", person.names);
    formik.setFieldValue("father_last_name", person.father_last_name);
    formik.setFieldValue("mother_last_name", person.mother_last_name);
  };

  const handleFormData = (label: string, value: string) => {
    formik.setFieldValue(label, value);
  };

  const previousView = () => {
    if (activeTab === 2) {
      formik.resetForm();
      formik.setFieldValue("selectedCity", "");
      setStatePayment(false);
      setTerminos(false);
      setPagar(false);
    }
    setActiveTab(activeTab - 1);
  };

  const aplicarDescuento = () => {
    let mtoDescuento = 0;
    applyDiscount(formik.values.discount, formik.values.document_number)
      .then((response) => {
        if (response.state) {
          if (response.type === "MONTO") {
            mtoDescuento = response.amount;
          }
          if (response.type === "PORCENTAJE") {
            mtoDescuento = parseFloat(((response.amount / 100) * (formik.values.amount ?? 0)).toFixed(2));
          }
          formik.setFieldValue("mtoDescuento", mtoDescuento);
        }

        setValidateCoupon(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reiniciarDescuento = () => {
    formik.setFieldValue("mtoDescuento", 0);
    formik.setFieldValue("discount", "");
    setValidateCoupon({});
  };

  // const onVerificar = () => {
  //   navigate("/verificar");
  // };

  return (
    <div className="min-vh-100 row justify-content-center align-items-center">
      <div className="col-lg-8 col-md-10 col-12">
        <div className="card card-featured card-featured-primary mb-3 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 mb-3 text-center">
                <img src="/images/logo.png" alt="" className="logo-inscription" />
                <h4 className="mt-3">Inscripción online</h4>
              </div>
              <div className="col-md-12 twitter-bs-wizard">
                <Tabs activeTab={activeTab} />

                <form className="form form-horizontal" onSubmit={formik.handleSubmit}>
                  <div className="tab-content twitter-bs-wizard-tab-content">
                    <Eventos setActiveTab={setActiveTab} requirements={requirements} activeTab={activeTab} formik={formik} />
                    <DatosPersonales
                      onPersonSearch={personSearch}
                      handlePerson={handlePerson}
                      handleSelectChange={handleSelectChange}
                      handleFormData={handleFormData}
                      activeTab={activeTab}
                      formik={formik}
                      handleInputChange={handleInputChange}
                      errors={errors}
                    />
                    <InformacionAcademia
                      ubigeoSearch={ubigeoSearch}
                      handleSelectChange={handleSelectChange}
                      handleFormData={handleFormData}
                      activeTab={activeTab}
                      formik={formik}
                      handleInputChange={handleInputChange}
                      errors={errors}
                    />
                    <Pago
                      reiniciarDescuento={reiniciarDescuento}
                      aplicarDescuento={aplicarDescuento}
                      validateCoupon={validateCoupon}
                      transactions={transactions}
                      requirements={requirements}
                      activeTab={activeTab}
                      formik={formik}
                      handleSelectChange={handleSelectChange}
                      handleInputChange={handleInputChange}
                      handleFormData={handleFormData}
                      errors={errors}
                      statePayment={statePayment}
                      setStatePayment={setStatePayment}
                      terminos={terminos}
                      setTerminos={setTerminos}
                      pagar={pagar}
                      setPagar={setPagar}
                    />
                  </div>
                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                    {activeTab === 1 ? (
                      <></>
                    ) : (
                      <li className="previous">
                        <button className="btn btn-primary btn-xs" type="button" onClick={previousView}>
                          Atrás
                        </button>
                      </li>
                    )}
                    {activeTab > 1 && activeTab !== 4 && (
                      <li className="next">
                        <button type="submit" className="btn btn-primary btn-xs">
                          Siguiente
                        </button>
                      </li>
                    )}
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <button onClick={onVerificar} className="btn btn-info btn-xs" type="button">
            Verificar inscripción
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default RegisterPage;
