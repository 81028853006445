import { useDispatch } from "react-redux";
/**Services */
import * as inscriptionsService from "../services/inscriptions.service";
/**Redux */
import { setLoading } from "../redux/states/loading.slice";
/**Models */
import { InscripcionRequest } from "../models/requests";

export function useInscriptions() {
  const dispatch = useDispatch();

  const personSearch = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.personSearch(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const ubigeoSearch = async (text: string) => {
    dispatch(setLoading(false));
    try {
      const response = await inscriptionsService.ubigeoSearch(text);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getRequirements = async () => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.requirements();
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const transactions = async (request: InscripcionRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.transactions(request);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const authorizationPayment = async (uuid: string) => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.authorizationPayment(uuid);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const applyDiscount = async (code: string, document_number: string) => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.applyDiscount(code, document_number);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const verificar = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await inscriptionsService.verificar(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { verificar, personSearch, ubigeoSearch, getRequirements, transactions, authorizationPayment, applyDiscount };
}
